@font-face {
    font-family: 'Open Sans Light';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    /* src: local('Open Sans Light'), url(OpenSans/OpenSans-Light.ttf) format('trutype'); */
}

@font-face {
    font-family: 'Open Sans Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}

@font-face {
    font-family: 'Open Sans Semibold';
    font-style: normal;
    font-weight: 800;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

@font-face {
    font-family: 'Open Sans Extrabold';
    font-weight: 500;
    src: url('./OpenSans-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans Bold';
    font-weight: 500;
    src: url('./OpenSans-Bold.woff') format('woff');
}

body {
    display: flex;
    justify-content: center;
}

.app-container {
    max-width: 1920px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
}

.shadow-for-text {
    box-shadow: #F47126 0px 2px 0px 3px;
    width: 95%;
    margin: auto;
}


.header-container {
    max-width: 1850px;
}

.header-logo {
    height: 75%;
    width: 75%;
    /* background-color: #000000; */
}

.bg-dark {
    background-color: black !important;
}

.offcanvas-list {
    margin: 0;
    padding: 0;
    font-weight: 18;
}

.navbar-toggler-icon {
    filter: invert(1) !important;
}

.hide508 {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.me-auto {
    margin-left: 65% !important;
}

.furniture-header-web {
    font-size: 1.7vw;
    text-align: center;
}

/* 
@media only screen and (max-width: 1024px) {
    .home-info-row {
        height: 47% !important;
    }

    .information-col {
        padding: 15vw 3vw !important; 
    }
    .upper-text-container {
        font-size: 16px !important;
    }
    .lifestyle-container {
        font-size: 5vw !important;
    }
    .home-image-container {
        height: 53% !important;
    }
    .about-container {
        height: 74vh !important;
    }
    .home-container {
        height: 74vh !important;
        max-width: 100%;
    }
    .about-image-row {
        height: 53% !important;
    }
    .footer-container {
        height: 25vh !important;
    }
}  */

.information-col {
    background-color: #CABEB6;
    /* padding: 4vw 4vw; */
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-text {
    background-color: #FFFFFF !important;
    height: 21vw;
    padding: 0px;
    width: 27vw;
}

.home-container {
    max-width: 1920px;
}

.footer-container {
    background-color: #434951;
    flex: 1;
    /* min-height: 45vh !important; */
    /* height: 56% !important; */
    /* min-height: 35vh; */
    padding: 20px;
    color: white;
    margin: 0;
    max-width: 100%
}

.footer-heading {
    justify-content: space-between;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.footer-label {
    font-family: 'Open Sans Semibold';
    font-size: medium;
}

/* .footer-text {
    font-size: small;
} */

.footer-copyright {
    font-weight: 100;
    font-family: Open Sans Light;
    padding-top: 15px;
    padding-bottom: 15px;
}

.footer-items {
    margin: 0;
    text-align: right;
    font-family: 'Open Sans Light';
}

.offcanvas-end {
    width: 39% !important;
    background-color: #000000 !important;
}

.normal-header-items {
    list-style: none;
    margin: 0;
    font-family: 'Open Sans Semibold' !important;
}

.normal-header-items>li {
    margin-left: 15px;
    display: inline;
    font-weight: 500;
}

.normal-header-link {
    text-decoration: none !important;
    font-size: medium;
    color: #fff
}

a.normal-header-link:hover {
    color: red;
}

a.menu-item {
    color: white !important;
}

a.menu-item:hover {
    color: red !important;
}

.footer-description {
    margin-bottom: 20px;
    font-family: Open Sans Light
}

.footer-links {
    margin: '0';
    padding-left: '59%'
}

.footer-label {
    margin-bottom: 10px;
}

.footer-text {
    font-size: medium;
    padding-top: 15px;
    padding-bottom: 15px;
    /* letter-spacing: 0.1em; */
}

.contact-container {
    max-width: 100%;
    margin: 0;
    /* min-height: 20vw; */
    /* height: auto; */
}

.contact-info-container {
    padding: 0;
    background-color: #CABEB6;
    min-height: 20vw;
    height: auto;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.contact-info-inner {
    margin-top: 1vw;
    margin-left: 2vw;
}

.contact-info-divider {
    height: 1;
    opacity: 1;
    background-color: #333;
    margin: 1.5rem 1.5rem 0.5rem 0rem;
}

.contact-inner-container {
    padding: '0' !important;
    background-color: "#CABEB6" !important;
    min-height: '60vw';
}

.contact-heading {
    font-family: 'Open Sans Regular';
    margin-bottom: 10px;
    /* font-size: 3vw; */
    font-weight: 400;
}

.contact-sub-heading {
    margin-top: 1vw;
    margin-bottom: 0.5vw;
    font-family: "Open Sans Extrabold" !important;
    /* font-size : large; */
}

.corporate-details {
    /* font-size: 1.5vw; */
    font-size: small;
    font-family: "Open Sans Semibold" !important;
}

.label-text {
    padding-top: 8px;
    font-size: 2.2vw;
    font-weight: 400;
}

.quality-header {
    font-size: 1.3vw;
    margin-bottom: 1vw;
}

.quality-sec-header {
    font-weight: bold !important;
    font-size: 1.5vw;
    font-family: 'Open Sans Bold';
    letter-spacing: 0.1em;
}

.quality-details-container {
    /* padding: 8px; */
}

.frames-container,
.springs-container,
.tailoring-container {
    padding-top: 50px;
}

.quality-header-mob {
    padding-bottom: 5px;
    padding-top: 5vw;
    text-align: center;
    font-size: 3.5vw;
    font-family: "Open Sans Bold";
    letter-spacing: 0.15em;
}

.quality-points {
    font-size: 1.2vw;
    padding-left: 1rem;
}

.about-container {
    max-width: 100%;
    margin: 0;
    position: relative;
}

.about-heading {
    text-align: center;
    justify-content: center;
    margin: 40px 0px 20px;
    color: #f17c38;
    font-size: x-large;
}

.about-description {
    justify-content: center;
    margin: 8px 2vw 49px;
    font-size: 1.4vw;
}

.about-label {
    position: absolute !important;
    top: 30px;
    background-color: white;
    width: 40%;
    font-size: 2.5vw;
    padding: 1px 22px;
}

.upper-text-container {
    justify-content: center;
    height: 32.33%;
    font-weight: 34;
    font-size: 1.7vw;
    ;
    padding-top: 13%;
    padding-bottom: 0.5vw;
    font-family: 'Open Sans Regular';
}

.lifestyle-container {
    background-color: #F47126;
    height: 36.33%;
    color: white;
    font-family: 'Open Sans Light';
    justify-content: center;
    margin: 0px;
    font-size: 4vw;
    align-items: center;
    text-align: center;
}

.lifestyle-text {
    opacity: 0.8;
}

.header-navigation-item {
    display: block;
    margin-left: 15px;
}

.footer-navigation-item {
    display: inline;
    margin-left: 15px;
}

.footer-header {
    font-weight: 500;
    font-size: 1.5vw;
    white-space: nowrap;
}

.home-info-row {
    /* height: 50vh; */
}

.label-text-header {
    font-family: 'Open Sans Semibold';
    font-size: 1.6vw;
    font-weight: 400;
    margin-bottom: 0.4em !important;
}

.home-image-container-mob {
    display: none;
}

.second-home-image {
    padding: 0;
    height: 100%;
    display: block;
}

.second-home-image-mob {
    display: none;
}

.about-image-row {
    display: block;
}

.about-image-row-mob {
    display: none;
}

.contact-image {
    display: block;
    padding: 0;
}

.quality-image {
    display: block;
    padding: 0;
}

.contact-image-mob {
    display: none;
}

.quality-image-mob {
    display: none;
}

/* Lesser then 300px screens */
@media (max-width: 359px) {
    /* .me-auto {
        margin-left: 50% !important;
    } */

    .quality-disclaimer {
        font-size: 3vw;
    }

    .app-container {
        height: 100vmax;
    }

    .contact-info-inner {
        margin-top: 7vw;
    }

    .high-point {
        margin-bottom: 50px;
    }

    .about-description {
        font-size: 3vw;
    }

    .quality-text-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .contact-image {
        display: none;
    }

    .quality-image {
        display: none;
    }

    .contact-image-mob {
        padding: 0;
        display: block;
        /* height: 100% !important; */
    }

    .quality-image-mob {
        padding: 0;
        display: block;
        /* height: 100% !important; */
    }

    .home-image-container {
        display: none;
    }

    .home-image-container-mob {
        display: block;
    }

    .second-home-image {
        padding: 0;
        height: 75%;
    }

    .second-home-image {
        padding: 0;
        height: 75%;
    }

    .label-text-header {
        font-size: 5.5vw;
    }

    .contact-heading {
        /* font-size: 9vw; */
    }

    .offcanvas-navbar {
        display: flex !important;
    }

    .web-quality-view {
        display: none;
    }

    .normal-navbar {
        display: none !important;
    }

    .home-container {
        /* height: 63vh; */
    }

    .home-info-row {
        height: 54%;
    }

    .info-text-mobile {
        /* padding-top: 12px !important; */
        /* height: 15% !important; */
    }

    .info-text-one {
        padding-left: 13px;
        font-size: 12px;
    }

    .info-text-line {
        height: 2vw;
        background-color: #F47126;
    }

    .lifestyle-text-mobile {
        padding-top: 4px;
        /* height: 15%; */
        font-size: 25px;
        color: #F47126;
    }

    .furniture-header-mob {
        font-size: 3.5vw;
        margin-bottom: 1vw;
        padding-left: 3px;
        white-space: nowrap;
    }

    .footer-label {
        width: 75%;
    }

    .footer-links-container,
    .empty-column {
        display: none;
    }

    .web-quality-view {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .information-col {
        display: none;
    }

    .info-text-mobile {
        /* padding-top: 22px; */
        padding-right: 0px;
        padding-left: 4px;
        /* height: 25%; */
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .info-text-line {
        height: 2vw;
        background-color: #F47126;
        width: 85%;
    }

    .lifestyle-text-mobile {
        padding-top: 8.5px;
        padding-left: 8px;
        display: block !important;
        font-size: 8vw;
    }

    .lifestyle-header-mob {
        font-weight: 350;
    }

    .footer-header {
        font-size: 5vw;
        white-space: nowrap;
    }

    .mobile-quality-view {
        padding: 0px;
    }

    .quality-header {
        font-size: 2.5vw;
    }

    .quality-points {
        font-size: 2vw;
    }

    .quality-details-container {
        padding-top: 20px;
    }
}

@media (min-width: 360px) and (max-width: 660px) and (orientation: landscape) {
    .home-container {
        height: 100vh;
    }

    .app-container {
        height: 100vmax;
    }

}

@media (min-width: 450px) and (max-width: 550px) {
    .app-container {
        height: 100vmax;
    }

}

/* For Mobile Screens */
@media (min-width: 360px) and (max-width: 660px) {

    /* .me-auto {
        margin-left: 50% !important;
    } */
    .quality-disclaimer {
        font-size: 2.5vw !important;
    }

    .app-container {
        height: 100vmax;
    }


    .high-point {
        margin-bottom: 50px;
    }

    .contact-info-inner {
        margin-top: 7vw;
    }

    .about-container {
        height: auto;
    }

    .about-description {
        font-size: 3.5vw;
    }

    .quality-text-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .text-container {
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .contact-image {
        display: none;
    }

    .quality-image {
        display: none;
    }

    .contact-image-mob {
        padding: 0;
        display: block;
        /* height: 100% !important; */
    }

    .quality-image-mob {
        padding: 0;
        display: block;
        /* height: 100% !important; */
    }

    .about-image-row {
        display: none !important;
    }

    .about-image-row-mob {
        display: block !important;
        /* height: 45%; */
    }

    .second-home-image-mob {
        display: block;
        padding: 0;
        height: 75%;
        width: 100%;
    }

    .second-home-image {
        display: none;
    }

    .label-text-header {
        font-size: 5.5vw;
    }

    .contact-heading {
        /* font-size: 9vw; */
    }

    .footer-header {
        font-size: 3.5vw;
        white-space: nowrap;
    }

    .furniture-header-mob {
        margin-bottom: 1vw;
        font-size: 3.8vw;
        /* padding-left: 22px; */
    }

    .lifestyle-header-mob {
        font-size: 10vw;
        font-weight: 350;
        line-height: 1;
    }

    .second-home-image {
        padding: 0;
        height: 75%;
    }

    .info-text-one {
        /* margin-left: 1px !important; */
        font-size: 'medium' !important;
    }

    .offcanvas-navbar {
        display: flex !important;
    }

    .quality-header {
        font-size: 2vw;
    }

    .lifestyle-container {
        font-size: 6vw !important;
    }

    .upper-text-container {
        font-size: 8.5px !important;
    }

    .home-container {
        /* height: 67vh !important; */
        max-width: 100% !important;
    }

    .home-image-container {
        display: none;
    }

    .home-image-container-mob {
        display: block;
    }

    .upper-text-container {
        padding-top: 8vw !important;
    }

    .normal-navbar {
        display: none !important;
    }

    .web-quality-view {
        display: none;
    }

    .quality-points {
        font-size: 2.7vw !important;
        /* letter-spacing: 0.05em; */
        margin-bottom: 0px;
    }

    .mobile-quality-view {
        padding: 0;
        /* padding-left: 0px;
        padding-right: 0px; */
    }

    .about-label {
        display: none;
    }

    .info-text {
        background-color: #FFFFFF !important;
        height: 18vw;
        padding: 0px;
        width: 27vw;
    }

    .upper-text-container {
        padding-bottom: 7%;
        padding-top: 18% !important;
        font-size: 10px !important;
        transform: scale(1, 1.3);
    }

    .home-info-row {
        height: 54% !important;
    }

    .information-col {
        background-color: #CABEB6;
        /* padding: 15vw 3vw !important; */
        /* display: flex; */
        display: none;
        justify-content: center;
        align-items: center;
    }

    .info-text-mobile {
        /* padding-top: 21px; */
        /* padding-left: 4px; */
        padding-right: 0px;
        /* height: 25%; */
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .info-text-line {
        height: 2vw;
        background-color: #F47126;
        width: 92%;
    }

    .lifestyle-text-mobile {
        display: block !important;
        /* height: 25%; */
        padding-top: 6px;
        padding-left: 8px;
        color: #F47126;
        font-size: 10vw;
    }

    .footer-container {
        background-color: #434951;
        flex: 1;
        padding: 20px;
        color: white;
        margin: 0;
        max-width: 100%
    }

    .footer-label {
        width: 75% !important;
    }

    .footer-links-container,
    .empty-column {
        display: none;
    }

}

@media (min-width: 540px) and (max-width: 600px) {
    .quality-disclaimer {
        font-size: 2vw;
    }

    .app-container {
        height: 100vmax;
    }

    .about-container {
        height: auto;
    }

    .contact-info-inner {
        margin-top: 7vw;
    }

    .footer-label {
        width: 75%;
    }
}

@media (min-width: 661px) and (max-width: 711px) {
    .quality-disclaimer {
        font-size: 2vw;
    }

    .app-container {
        height: 100vmax;
    }

    .label-text-header {
        font-size: 2.6vw;
    }

    .about-description {
        font-size: 2.4vw;
    }

    .contact-info-inner {
        margin-top: 7vw;
    }

    .home-info-row {
        /* height: 25vh; */
    }

    .text-container {
        display: none;
    }

    .frames-container {
        padding-top: 23px;
    }

    .web-quality-view {
        max-width: 100%;
        padding: 0;
    }

    .second-home-image {
        padding: 0;
        height: auto;
    }

    /*  .footer-container {
        height: 41vw !important;
    } */
    .footer-header {
        font-size: 2.5vw;
        white-space: nowrap;
    }

    .offcanvas-navbar {
        display: none;
    }

    .mobile-quality-view {
        display: none;
    }
}

/* For Mobile Landscape screens */
@media only screen and (min-width: 720px) and (max-width: 916px) and (orientation: landscape) {
    .app-container {
        height: 100vmax;
    }

    /* .me-auto {
        margin-left: 50% !important;
    } */

    .text-container {
        display: none;
    }

    .home-container {
        height: auto !important;
    }

    .about-container {
        height: auto !important;
    }

    .home-info-row {
        /* height: 45%; */
    }

    /* .footer-container {
        height: auto !important;
    } */
    .lifestyle-container {
        font-size: 5.5vw;
    }

    .upper-text-container {
        font-size: 2.3vw !important;
    }

    .offcanvas-navbar {
        display: none;
    }
}

/* For Tablet Screens */
@media(min-width: 712px) and (max-width: 920px) {
    .quality-disclaimer {
        font-size: 1.5vw !important;
    }

    .app-container {
        height: 100vmax;
    }

    /* .me-auto {
        margin-left: 50% !important;
    } */

    .high-point {
        margin-bottom: 50px;
    }

    .contact-info-inner {
        margin-top: 7vw;
    }

    .label-text-header {
        font-size: 2.6vw;
    }

    .about-description {
        font-size: 2.3vw;
    }

    .text-container {
        display: none;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row !important;
    }

    .header-container {
        /* width: 93%; */
    }

    .offcanvas-navbar {
        display: none;
    }

    .mobile-quality-view {
        display: none;
    }

    .web-quality-view {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .home-info-row {
        /* height: 43% !important; */
    }

    .info-text {
        height: 25vw;
    }

    .upper-text-container {
        font-size: 16px !important;
    }

    .lifestyle-container {
        font-size: 5vw !important;
    }

    .home-image-container {
        /* height: 57% !important; */
    }

    .about-container {
        /* min-height: 65vh; */
        height: auto !important;
    }

    .home-container {
        /* height: 65vh; */
        max-width: 100% !important;
    }

    .about-image-row {
        height: 53% !important;
    }

    /*.footer-container {
        height: auto !important;
    } */
    .footer-header {
        font-size: 2.5vw;
        white-space: nowrap;
    }

    .footer-heading {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .quality-details-container {
        padding-top: 21px !important;
    }
}


/* Above Tablets */
@media (min-width: 920px) and (max-width: 1150px) {
    .quality-disclaimer {
        font-size: 1vw;
    }

    .app-container {
        height: 100vh;
    }


    .high-point {
        /* margin-bottom: 50px; */
    }

    .contact-heading {
        font-size: 3vw;
    }

    .corporate-details {
        font-size: 1vw;
    }

    .contact-sub-heading {
        font-size: 1.5vw;
    }

    .contact-info-divider {
        margin-top: 20px;
    }

    .contact-info-inner {
        /* margin-top: 2vw; */
        margin-bottom: 10px;
    }

    .contact-body {
        /* min-height: 65vh; */
    }

    .text-container {
        display: none;
    }

    .mobile-quality-view {
        display: none;
    }

    .offcanvas-navbar {
        display: none;
    }

    .web-quality-view {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (min-width: 1151px) {
    .app-container {
        height: 100vh;
    }

    .text-container {
        display: none;
    }

    .mobile-quality-view {
        display: none;
    }

    .web-quality-view {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.quality-text-container {
    padding-left: 25px;
    padding-right: 25px;
}

.info-text-mobile,
.lifestyle-text-mobile {
    display: none;
}

.offcanvas-navbar {
    display: none;
}

.container-fluid {
    background-color: #000000;
}

@media (min-width: 1400px) {
    .contact-info-inner {
        margin-top: 3vw;
    }
}

.quality-disclaimer {
    text-align: right;
    font-family: Open Sans Regular;
    font-style: italic;
    padding: 0.7vw;
    font-size: 1vw;
}